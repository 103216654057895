var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-card-text',[_c('v-container',{staticClass:"px-0 py-0"},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{staticClass:"text-reference",attrs:{"cols":"auto"}},[_vm._v(" "+_vm._s(_vm.$t('reference'))+": ")]),_c('v-col',{attrs:{"cols":"3"}},[_c('v-select',{staticClass:"term-select",attrs:{"items":_vm.getCompanyBusinessYears,"item-value":"term","item-text":"term","solo":"","flat":"","dense":"","background-color":"term-select","color":"primary","hide-details":"","append-icon":"$customChevronDown"},on:{"change":_vm.getDataFromApi},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t('year_label', { number: item.term, start: item.start_date.slice(0, -3), end: item.end_date.slice(0, -3) }))+" ")]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t('year_label', { number: item.term, start: item.start_date.slice(0, -3), end: item.end_date.slice(0, -3) }))+" ")]}}]),model:{value:(_vm.selectedTerm),callback:function ($$v) {_vm.selectedTerm=$$v},expression:"selectedTerm"}})],1)],1)],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('Table',{attrs:{"headers":_vm.generatedHeaders,"items":_vm.items,"number-of-pages":1,"total-records":12,"footer":false},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
var index = ref.index;
var headers = ref.headers;
return [_c('tr',_vm._l((headers),function(header){return _c('td',{key:((header.value) + "-" + index + "-td"),staticClass:"text-center"},[(!Number.isInteger(item[header.value]))?[_c('span',{class:header.value === 'month' ? 'font-weight-bold' : ''},[_vm._v(" "+_vm._s(item[header.value])+" ")])]:[(header.value === 'transition')?[(item.transition !== 0)?[_c('span',{class:item.transition > 0 ? 'text-green' : 'text-red'},[_vm._v(" "+_vm._s(item.transition > 0 ? '+' : '')+" "+_vm._s(_vm._f("toCurrency")(item.transition))+" ")])]:[_vm._v("--")]]:[_vm._v(" "+_vm._s(_vm._f("toCurrency")(item[header.value]))+" ")]]],2)}),0)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }