<template>
  <v-row>
    <v-col cols="12">
      <v-card>
        <v-card-text>
          <v-container class="px-0 py-0">
            <v-row align="center">
              <v-col cols="auto" class="text-reference">
                {{ $t('reference') }}:
              </v-col>
              <v-col cols="3">
                <v-select
                  :items="getCompanyBusinessYears"
                  item-value="term"
                  item-text="term"
                  v-model="selectedTerm"
                  @change="getDataFromApi"
                  solo
                  flat
                  dense
                  class="term-select"
                  background-color="term-select"
                  color="primary"
                  hide-details
                  append-icon="$customChevronDown"
                >
                  <template v-slot:item="{ item }">
                    {{
                      $t('year_label', {
                        number: item.term,
                        start: item.start_date.slice(0, -3),
                        end: item.end_date.slice(0, -3)
                      })
                    }}
                  </template>
                  <template v-slot:selection="{ item }">
                    {{
                      $t('year_label', {
                        number: item.term,
                        start: item.start_date.slice(0, -3),
                        end: item.end_date.slice(0, -3)
                      })
                    }}
                  </template>
                </v-select>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-col>

    <v-col cols="12">
      <v-card>
        <Table
          :headers="generatedHeaders"
          :items="items"
          :number-of-pages="1"
          :total-records="12"
          :footer="false"
        >
          <template v-slot:item="{ item, index, headers }">
            <tr>
              <td
                class="text-center"
                v-for="header in headers"
                :key="`${header.value}-${index}-td`"
              >
                <template v-if="!Number.isInteger(item[header.value])">
                  <span
                    :class="header.value === 'month' ? 'font-weight-bold' : ''"
                  >
                    {{ item[header.value] }}
                  </span>
                </template>

                <template v-else>
                  <template v-if="header.value === 'transition'">
                    <template v-if="item.transition !== 0">
                      <span
                        :class="item.transition > 0 ? 'text-green' : 'text-red'"
                      >
                        {{ item.transition > 0 ? '+' : '' }}
                        {{ item.transition | toCurrency }}
                      </span>
                    </template>

                    <template v-else>--</template>
                  </template>
                  <template v-else>
                    {{ item[header.value] | toCurrency }}
                  </template>
                </template>
              </td>
            </tr>
          </template>
        </Table>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import Table from '@/components/admin/partials/Table/Table'
import { mapGetters } from 'vuex'

export default {
  name: 'index',
  components: { Table },
  created() {
    this.loading = true
    this.selectedTerm = this.getCompanyBusinessYears[0].term
    this.getDataFromApi()
  },
  computed: {
    ...mapGetters(['getCompanyBusinessYears', 'getCompanyBankBalances']),
    generatedHeaders() {
      let headers = [...this.headers]
      if (this.banks) {
        for (const bank of this.banks) {
          headers.push({
            text: bank.name,
            align: 'center',
            value: `bank_${bank.id}`,
            sortable: false
          })
        }
      }
      return headers
    }
  },
  data() {
    return {
      selectedTerm: null,

      banks: null,

      headers: [
        {
          text: this.$t('page_company_bank_balance_table_header_month'),
          align: 'center',
          value: 'month',
          sortable: false
        },
        {
          text: this.$t('page_company_bank_balance_table_header_total_balance'),
          align: 'center',
          value: 'total_balance',
          sortable: false
        },
        {
          text: this.$t('page_company_bank_balance_table_header_transition'),
          align: 'center',
          value: 'transition',
          sortable: false
        }
      ],

      items: []
    }
  },
  methods: {
    getDataFromApi() {
      this.banks = null
      this.$store
        .dispatch('COMPANY_BANK_BALANCE_GET', {
          id: this.$route.params.id,
          term: this.selectedTerm
        })
        .then(() => {
          this.mapItems()
        })
        .finally(() => (this.loading = false))
    },

    mapItems() {
      if (this.getCompanyBankBalances.length === 0) {
        this.banks = null
        this.items = []
      }
      let items = []
      for (const bank_balance of this.getCompanyBankBalances) {
        let value = bank_balance.balance
        let item = {
          month: bank_balance.month.id,
          total_balance: value,
          transition: bank_balance.transition
        }
        if (this.banks === null) {
          this.banks = bank_balance.banks
        }
        for (const bank of bank_balance.banks) {
          item[`bank_${bank.id}`] = value
        }
        items.push(item)
      }
      this.items = items
    },

    getTermLabel() {
      return this.terms.find(item => item.value === this.selectedTerm).label
    }
  }
}
</script>

<style lang="scss" src="./style.scss" scoped></style>
